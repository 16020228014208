// Variables
$main-color-orange: #f57e00;
$main-color-blue: #002d62;
$main-color-green: #e9eae2;
$main-text-color: #848484;

// Body

* {
	outline: none !important;
}

body {
	font-family: "sofia-pro",sans-serif;
	font-weight: 300;
	font-size: 18px;
	background-color: #FFF;
	color: $main-text-color;
	padding-top: 160px;
}

img {
	height: auto;
}

.visisble-xs {
	display: none;
}

.static {
	position: static !important;
}

.text-center {
	text-align: center;
}

.no-padding {
	padding: 0;
}

a {
	color: $main-text-color;
	text-decoration: underline;

	&:active {
		color: $main-text-color;
		text-decoration: underline;
	}
	&:visited {
		color: $main-text-color;
		text-decoration: underline;
	}
	&:hover {
		color: $main-text-color;
		text-decoration: none;
	}
}

// Headers
h1,h2,h3,h4,h5 {
	font-weight: 700;
	color: $main-color-blue;
	padding: 0;
	margin: 0;
	font-family: "sofia-pro",sans-serif !important;
}

// Button
.button {
	width: auto;
	height: auto;
	padding: 10px 15px;
	background-color: $main-color-blue;
	border-radius: 3px;
	border: none;
	color: #FFF;
	font-weight: 400;
	outline: none;
	outline-style: none;
	transition: all .3s;
	text-decoration: none;
}

// Container position
.container-pos1 {
	margin-top: -100px;
}

.content {
	padding: 50px 50px 100px 50px !important;
}

.parent-5 {

	.content {
		padding: 0px !important;
	}
}

.container-pos2 {
	background-color: #FFF;
	margin-top: -150px;
	padding: 50px;
}

// Top bar
.top_bar {
	width: 100%;
	height: 50px;
	padding: 0px 10px;
	background-color: $main-color-green;
	position: fixed;
	top: 0;
	z-index: 999;

	// First list
	.first_ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		float: left;
		height: 50px;
		padding: 8px 0px 0px 0px;
	
		li {
			display: inline;
			margin-right: 15px;
			background-image: url('../img/check.svg');
			background-size: 15px;
			background-repeat: no-repeat;
			background-position: left center;
			padding-left: 20px;
			font-size: 13px;
			font-weight: 300;
			color: #484848;
		}
	}

	// Second list
	.second_ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		height: 50px;
		float: right;

		li {
			float: left;
			margin-right: 20px;
			font-size: 18px;
			font-weight: 300;
			height: 50px;
			padding: 10px 13px;
			
			&:first-child {
				background-color: #FFFFFF;
			}
			
			i.whatsapp {
				display: inline-block;
				width: 21px;
				height: 21px;
				background: url('../img/whatsapp.svg') 0px 0px no-repeat;
				background-size: 21px 21px;
				margin-bottom: -4px;
			}			

			i.phone {
				display: inline-block;			
				width: 21px;
				height: 21px;			
				background: url('../img/phone.svg') no-repeat;
				background-size: 21px 21px;
				margin-bottom: -4px;
			}
			
			a {
				text-decoration: none;
			}
		}
	}
}

// Navigation
.navigation {
	width: 100%;
	height: auto;
	padding: 25px;
	background-color: #FFF;
	position: fixed;
	top: 46px;
	z-index: 999;
	box-shadow: 1px 1px 5px rgba(0,0,0,.1);
	transition: all .3s;

	// Logo
	.logo {
		float: left;
		width: 225px;
		height: 67px;
		background: url('../img/logo.svg') center no-repeat;
		background-size: 260px 77px; 
	} 

	// Nav
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 30px;
		float: right;
		width: auto;

		li {
			display: inline;
			margin-right: 25px;
			position: relative;

			&.active {
			
				a {
					color: #40c8f4;
				}
				
				li {
				
					a {
						color: $main-text-color;
					}
					
					&.active {
					
						a {
							color: #40c8f4;
						}
					
					}
				
				}
			
			}
			
			a {
				font-weight: 300;
				font-size: 18px;
				color: $main-text-color;
				text-decoration: none;
				transition: all .3s;

				&:hover {
					color: $main-color-orange;
				}

				&.has-sub {
					background-image: url('../img/dropdown.svg');
					background-size: 8px 8px;
					-webkit-background-position: right 0px bottom 8px;
					background-position: right 0px bottom 8px;
					background-repeat: no-repeat;
					padding-right: 15px;
					cursor: pointer;
				}

				&.active {
					color: $main-color-orange;
				}

			}
		}
	}

	// Sub nav
	li ul {
		position: absolute;
		left: 0;
		top: 0;
		padding-top: 30px;
		width: 200px;
		background-color: #FFF;
		display: none;
		z-index: 999;

		li {
			display: block;
			padding: 10px 20px;
			width: 100%;
			min-width: 200px;
			border-bottom: 1px solid rgba(0,0,0,.1);
			margin-right: 0;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	// Nav icon
	.navicon {
		float: right;
		font-size: 18px;
		color: $main-text-color;
		display: none;
		cursor: pointer;
	}
}

// Header
.header {
	width: 100%;
	height: auto;
	text-align: center;
	color: #FFF;
	background-size: cover;
	background-image: url('../img/header.jpg');
	background-position: 50%;
	height: 425px;	
	padding-top: 70px;

	h1 {
		font-size: 46px;
		color: #FFF;
		margin-bottom: 20px;
		text-shadow: 0px 0px 20px rgba(0,0,0,0.3);
	}
	h2 {
		font-size: 28px;
		color: #FFF;
		font-weight: 300;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
	}

	// Pages
	&.header-home {
		background-image: url('../img/header.jpg');
		background-position: 50%;
		height: 425px;

		@media (max-width: 767px) {
			background-position: right -70px center;
		}
	}
	&.header-producten {
		background-image: url('../img/header_producten.jpg');
		background-position: 25%;
		height: 340px;
	}
	
	&.header-3 {
		background-image: url('../img/header7.jpg');
		background-position: 25%;
		height: 340px;	
	}	
	
	&.header-6 {
		background-image: url('../img/header_producten.jpg');
		background-position: 25%;
		height: 340px;	
	}
	
	&.header-7 {
		background-image: url('../img/header8.jpg');
		background-position: 25%;
		height: 340px;	
	}
	
	&.header-8 {
		background-image: url('../img/header_producten.jpg');
		background-position: 25%;
		height: 340px;	
	}	
	
	&.header-9 {
		background-image: url('../img/header4.jpg');
		background-position: 25%;
		height: 340px;	
	}		
	
	&.header-10 {
		background-image: url('../img/header3.jpg');
		background-position: 25%;
		height: 340px;	
	}	
	
	&.header-11 {
		background-image: url('../img/header3.jpg');
		background-position: 25%;
		height: 340px;	
	}	
	
	&.header-12 {
		background-image: url('../img/header4.jpg');
		background-position: 25%;
		height: 340px;	
	}		

	&.header-13 {
		background-image: url('../img/header2.jpg');
		background-position: 25%;
		height: 340px;	
	}	

	&.header-14 {
		background-image: url('../img/header6.jpg');
		background-position: 25%;
		height: 340px;	
	}	

	&.header-15 {
		background-image: url('../img/header5.jpg');
		background-position: 25%;
		height: 340px;	
	}	

	&.header-16 {
		background-image: url('../img/header3.jpg');
		background-position: 25%;
		height: 340px;	
	}	
	
	&.header-19 {
		background-image: url('../img/header9.jpg');
		background-position: 25%;
		height: 340px;	
	}		
	
}

// Offers homepage
.offers {

	// Flex boxes
	.flex-boxes {
		display: flex;
	}

	// Above
	.two_bar {
		background-color: $main-color-green;
		padding: 30px;
		overflow: auto;
		
		a {
			text-decoration: none;
		}

		h2 {
			font-size: 24px;
			background-image: url('../img/arrow.svg');
			background-position: left center;
			background-size: 10px;
			background-repeat: no-repeat;
			padding-left: 20px;
			font-weight: 400;
		}
		p {
			padding-left: 20px;
		}
	}

	// Offer
	.offer {
		border-left: 1px solid rgba(0,0,0,.1);
		text-align: center;
		padding-top: 40px;
		padding-bottom: 40px;

		a {
			text-decoration: none;
		}
		
		&:last-child {
			border-right: 1px solid rgba(0,0,0,.1);
		}

		h3 {
			font-size: 18px;
		}
		span {
			font-size: 22px;
			color: #40c8f4;
			font-weight: 300;
		}
		img {
			width: 130px;
			margin-bottom: 10px;
		}

		.button {
			display: inline-block;
			clear: left;
			color: $main-color-orange;
			background: transparent;
			font-size: 20px;
			border: none;
			margin-top: 20px;
			text-decoration: none;
		}
	}

	// Month sale
	.month_sale {
		background-color: $main-color-orange;
		color: #FFF !important;
		padding: 0px;
		
		.placeholder {
			width: 100%;
			height: 365px;
			background: url('../img/placeholder.jpg');
			background-size: cover;
		}	
		
		.inner {
			padding: 30px;		

			h1 {
				color: #FFF !important;
			}
				
			h2 {
				font-size: 30px;		
				color: #FFF !important;
				font-family: "sofia-pro",sans-serif;
			}	

			h3 {
				font-size: 18px; 
				font-weight: normal;
				color: #FFF !important;
				font-family: "sofia-pro",sans-serif;
			}			
				
			img {
				border: 4px solid rgba(0,0,0,.1);
				max-width: 200px;
				width: 100%;
			}
				
			.button {
				float: left;
				clear: left;
				color: $main-color-orange;
				background: #FFF !important;
				margin-top: 15px;
			}
				
			p {
				font-weight: 300;
				color: #FFF;	
			}
				
			p:last-child {margin-top: 10px;}
			button {
				padding: 10px 15px;
				font-size: 18px;
				border-radius: 3px;
				border: none;
				color: $main-color-orange;
				margin-top: 10px;
				background: #FFF;
			}
		}		
	}

	// Moving
	.moving {
		background: url('../img/moving.jpg') bottom center no-repeat;
		color: #FFF;
		padding: 30px;
		flex: 1;

		h1 {
			font-size: 30px;
			color: #FFF;
			font-family: "sofia-pro",sans-serif;
		}
		p {
			margin-top: 30px;
			margin-bottom: 30px;
			color: #FFF;
		}
		a {
			color: #FFF;
			text-decoration: underline;
		}
	}
}

// Wide img
.wide-img {
	width: 100%;
	height: 420px;
	background: url('../img/intro-img.jpg') top center;
	overflow: auto;
}

// Intro
.intro {

	h1 {
		margin-bottom: 40px;
		font-size: 44px;
	}

	.reviews {
	
		text-align: center;
		margin-top: 50px;

		.icon-quote {
			display: inline-block;
			width: 120px;
			height: 120px;
			background: url('../img/message.svg') center no-repeat #43c8f5;
			background-size: 50px 50px;
			-webkit-border-radius: 50%;
			border-radius: 50%;
		}		
		
		h2 {
			font-size: 24px;
			margin: 10px 0px 10px 0px;
		}
		
		h3 {
			font-size: 18px;
			margin-top: 20px;
		}
		img {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		p {
			line-height: 27px;
		}
	}
}

// Blocks
.blocks {

	.footer-block {
		min-height: 380px;
	}

	.row {
		display: flex;
	}

	.block1 {
		background-image: url('../img/block-img.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.block2 {
		background: $main-color-blue;
		color: #FFF;
		font-size: 24px;
		padding: 30px;
		padding-top: 60px;

		h1 {
			color: #FFF;
			font-size: 36px;
			margin-bottom: 20px;
		}

		img {
			width: 70%;
		}
	}
	.block3 {
		background: $main-color-orange;
		color: #FFF;
		font-size: 24px;
		padding-top: 60px;
		padding: 45px;

		h1 {
			font-size: 36px;
			color: #FFF;
			margin-bottom: 20px;
		}
	}
}

// Footer
.footer {
	width: 100%;
	height: auto;
	background-color: $main-color-green;
	padding-top: 50px;
	padding-bottom: 50px;
	text-align: center;

	i.whatsapp {
		display: inline-block;
		width: 21px;
		height: 21px;
		background: url('../img/whatsapp.svg') 0px 0px no-repeat;
		background-size: 21px 21px;
		margin-bottom: -4px;
	}	
	
	h1 {
		font-size: 40px;
		margin-bottom: 30px;
	}

	input[type="text"],input[type="email"],input[type="tel"],textarea {
		border: 1px solid #d7d9d4;
		border-radius: 3px;
		width: 80%;
		padding: 10px 15px;
		outline: none;
		outline-style: none;
		margin-bottom: 5px;
	}
	
	button {
		width: 80%;
		height: auto;
		padding: 10px 15px 12px 15px;
		color: #FFF;
		outline: none;
		outline-style: none;
		text-align: center;
		background: #f57e00;
		border: 1px solid #d7d9d4;
		border-radius: 3px;
		font-weight: 400;
		transition: all .3s;

		&:hover {
			background: $main-color-blue;
		}
	}

	.button {
		background: $main-color-blue;
		width: auto;
		height: auto;
		padding: 10px 15px;
		color: #FFF;
		font-weight: 400;
		text-align: center;
		border: none;
		border-radius: 3px;
		transition: all .3s;

		&:hover {
			background: $main-color-orange;
		}
	}
	
	.errorField {
		border: 1px solid red !important;
	}
	
	p {
		margin-bottom: 30px;
	}
	
	.copyright {
		padding-top: 30px;
		font-size: 14px;
	}
}

// Products
.products {

	padding: 0px 15px !important;
	margin-bottom: 100px;
	
	.row {
		display: flex;
	}

	.product_view {
		position: relative;
		min-height: 350px;
		background-color: #FFF;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 0px;
		padding-right: 0px;
		border-bottom: 1px solid rgba(0,0,0,.1);

		&.actie-van-de-maand {
			background: #f57e00;
			color: #FFFFFF;
			padding: 20px 30px;
			
			h2 {
				color: #FFFFFF;
				font-size: 30px;
				padding: 0px 0px 5px 0px;
				margin: 0px;
			}
			
			h3 {
				color: #FFFFFF;
				font-size: 18px;
				font-weight: normal;
				padding: 0px 0px 0px 0px;
				margin: 0px 0px 20px 0px;
			}	

			p {
				padding: 0px 0px 15px 0px;
				margin: 0px;
			}	
			
			.button {
				color: #f57e00 !important;
				background: #FFFFFF !important;
			}
			
			a {
				float: left;	
					
				img {
					float: left;
					border: 4px solid #df7300;
				}				
			}
		}
		
		&:nth-child(odd) {
			border-left: 1px solid rgba(0,0,0,.1);
			border-right: 1px solid rgba(0,0,0,.1);
		}
		&:nth-child(even) {
			border-right: 1px solid rgba(0,0,0,.1);
		}
		&:nth-child(2n) {
			border-left: 1px solid rgba(0,0,0,.1);
		}
		
		.action {
			position: absolute;
			width: 85px;
			height: 85px;
			background: url('../img/action.svg');
			background-size: 85px 85px;
			top: 0px;
			left: 0px;
			z-index: 99;
		}		

		span {
			font-size: 22px;
			color: #40c8f4;
			font-weight: 300;

			strong {
				font-size: 31px;
				font-weight: 300;
			}
		}
		
		h1 {
			font-size: 27px;
			color: $main-color-blue;
			padding: 0px 0px 5px 0px;
			
			a {
				float: none;
				font-size: 27px;
				color: $main-color-blue;			
			}
		}
		
		h2 {
			font-size: 18px;
			color: $main-color-orange;
			margin-bottom: 15px;
			
			a {
				float: none;	
				font-size: 18px;
				color: $main-color-orange;			
			}
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			margin-top: 20px;
			margin-bottom: 20px;

			li {
				display: inline;
				margin-right: 5px;
			}
		}
		p {
			margin-bottom: 20px;
		}

		a.button {
			float: left;
			padding: 5px 10px;
			margin: 15px 5px 0px 0px;
			background-color: $main-color-orange;
			color: #FFF;
			font-size: 17px;

			&:hover {
				background-color: $main-color-blue;
			}
		}
		
		a.button:last-child {
			background-color: $main-color-blue;

			&:hover {
				background-color: $main-color-orange;
			}
		}

		.product_image {
			text-align: center;

			img {
				width: auto;
			}
		}
		
		strike {
			color: #e33127;
		}
	}

	.month_sale {
		background-color: $main-color-orange;
		color: #FFF;
		padding: 0px;

		h1 {
			font-size: 30px;
			color: #FFF;
		}
		img {
			border: 4px solid rgba(0,0,0,.1);
			max-width: 200px;
			width: 100%;
		}
		p {
			font-weight: 300; 
			color: #FFF !important;
		}
		
		p:last-child {margin-top: 10px;}
		button {
			padding: 10px 15px;
			font-size: 18px;
			border-radius: 3px;
			border: none;
			color: $main-color-orange;
			margin-top: 10px;
			background: #FFF;
		}
	}
}

.content-information {
	padding: 50px 0px;
}

.references {

	.reference {
		padding: 10px 0px 30px 0px;
		margin-bottom: 30px;
		border-bottom: 1px solid #e5e5e5;
		
		h3 {
			padding: 0px 0px 10px 0px;
		}
		
		p {
			padding: 0px 0px 10px 0px;
			margin: 0px;
		}
		
		strong {
			font-size: 20px;
		}
	}
	
}

// Product detail
.product_detail, .page_detail {
	
	.action {
		position: absolute;
		width: 85px;
		height: 85px;
		background: url('../img/action.svg');
		background-size: 85px 85px;
		top: 0px;
		left: 0px;
		z-index: 99;
	}			

	.thumbs {
		float: left;
		clear: both;
		width: 100%;
		list-style: none;
		padding: 0px 0px 20px 35px;
		
		li {
			float: left;
			margin: 10px 10px 0px 0px;
			cursor: pointer; 
			
			img {
				float: left;
				width: 70px;
				border: 1px solid #FFF;
			}
			
			&.selected {
				
				img {
					border: 1px solid #CCCCCC;
				}
			
			}
		}
	}

	.container {
		position: relative;
		background: #FFF;
		padding-top: 20px;
		
		.page-content {
			
			.faq {
				h3 {
					font-size: 20px;
					padding: 10px 0px 5px 0px;
					margin: 0px;
				}			
			}
			
			h2 {
				padding: 20px 0px 20px 0px;
				margin: 0px;
			}
			
			h3 {
				font-size: 20px;
				padding: 10px 0px 10px 0px;
				margin: 0px;
			}
			
			p {
				padding: 0px 0px 15px 0px;
				margin: 0px;
			}
			
			.button {
				float: left;
				color: #FFFFFF;
				margin-top: 15px;
			}
			
			.benefits {
			
				ul#benefits-list {
					list-style: none;
					padding: 0px;
					margin: 50px 0px 0px 0px;
					
					li {
						float: left;
						background: url('../img/check.svg') no-repeat;
						background-size: 42px 42px;
						min-height: 60px;
						width: 50%;
						padding: 0px 0px 0px 60px;
						margin: 0px 0px 15px 0px;
						color: #848484;
					}
				}
			
			}
			
		}
		
	}

	img {
		margin-left: auto;
		margin-right: auto;
		width: auto;
	}

	span {
		font-size: 22px;
		color: #40c8f4;
		font-weight: 300;

		strong {
			font-size: 31px;
			font-weight: 300;
				
			strike {
				color: #e33127;
			}			
		}
	}
	
	h1 {
		font-size: 38px;
		color: $main-color-blue;
		padding-bottom: 20px;
	}
	
	h2 {
		font-size: 25px;
		color: $main-color-orange;
		margin-bottom: 15px;
	}
	
	ul#labels {
		float: left;
		position: relative;
		width: 100%;
		clear: both;
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 35px;
		margin-bottom: 35px;

		li {
			float: left;
			margin: 0px 5px 5px 0px;
			width: 42px;
			height: 42px;
			text-align: center;
			cursor: pointer;
			
			img {
				width: 100%;
			}
			
			span {
				text-align: left;
				display: none;
				position: absolute;
				width: 300px;
				background: #FFFFFF;
				border: 1px solid #E5E5E5;
				padding: 10px;
				font-size: 14px;
				color: $main-text-color;
				bottom: 50px;
				left: 0px;
				z-index: 99;
				
				img {
					float: left;
					width: 60px;
					height: 60px;
					margin: 0px 10px 10px 0px;
				}
				
				p {
					float: left;
					width: -webkit-calc(100% - 70px);
					width: calc(100% - 70px);
				}				
			}
			
		}
	}
	ol {
		padding-left: 20px;
		margin-bottom: 30px;
	}

	.button {
		padding: 10px 20px;
		background-color: $main-color-orange;
		color: #FFF;
		font-size: 20px;
		overflow: auto;

		&:hover {
			background-color: $main-color-blue;
		}
		
		&.blue {
			background-color: $main-color-blue;

			&:hover {
				background-color: $main-color-orange;
			}	
		}
		
	}
		.button:last-child {
			background-color: $main-color-blue;

			&:hover {
				background-color: $main-color-orange;
			}
		}
}

// Product detail
.product_detail {
	
	a:first-child {
		float: right;
		color: $main-color-orange;
		text-decoration: none;
		margin-bottom: 30px;
		
		&.fancybox {
			float: none;
		}
	}
	
	.fancybox {
		float: none;
	}
}


#notice {
	display: none;
	position: fixed;
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 9999;
	
	#notice-box {
		position: absolute;
		width: 600px;
		min-height: 150px;
		background: #FFFFFF;
		top: 50%;
		left: 50%;
		margin-left: -300px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	
	.inner {
		float: left;
		width: 100%;
		padding: 30px;
		color: #000;
	}
	
	.close-notice {
		
		position: absolute;
		right: 15px;
		top: 15px;
		width: 32px;
		height: 32px;
		background: url('../img/close.png');
		cursor: pointer;
		
	}
}

#change_form, #contact_form {
	
	margin-top: 20px;
	
	h3 {
		margin: 20px 0px;
	}
	
	label {
		font-weight: 300;
		font-size: 18px;
		color: #848484;	

		small {
			float: left;
			clear: left;
			opacity: 0.7;
		}
		
	}
	
	input {
		float: left;
		max-width: 400px;
		margin: 0px 0px 10px 0px;
		
		&.errorField {
			border: 1px solid red;
		}
	}
	
	span {
		float: left;
		font-weight: 300;
		font-size: 18px;
		color: #848484;		
		margin-left: 10px;
	}
	
	select {
		max-width: 400px;
		margin: 0px 0px 10px 0px;
		
		&.errorField {
			border: 1px solid red;
		}		
	}	
	
	textarea {
		max-width: 400px;
		height: 100px;
		margin: 0px 0px 10px 0px;
		
		&.errorField {
			border: 1px solid red;
		}		
	}		
	
}

.preloader i {
	display: inline-block;
	width: 32px;
	height: 32px;
	background: url('../img/preload.svg') no-repeat;
	background-size: 32px 32px;
}

// Media Queries

// 1400px
@media (max-width: 1400px) {

	// Blocks
	.blocks {

		.block2 {
			font-size: 20px;
			padding: 15px;
			padding-top: 30px;

			h1 {
				font-size: 30px;
				margin-bottom: 20px;
			}
			img {
				width: 100%;
			}
		}
		.block3 {
			background: $main-color-orange;
			color: #FFF;
			font-size: 20px;
			padding-top: 30px;
			padding: 30px;

			h1 {
				font-size: 30px;
				margin-bottom: 20px;
			}
		}
	}
}

// 1200px
@media (max-width: 1200px) {

	// Top bar
	.top_bar {
		padding-left: 0;
		padding-right: 0;

		.first_ul {
			display: none;
		}
		.second_ul li {font-size: 16px;margin-left: 15px;margin-right: 0;}
	}

	// Body
	body {
		padding-top: 135px;
	}

	// Navigation
	.navigation {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 0;
		padding-left: 0;

		// Logo
		.logo {
			width: 130px;
			background-size: 150px 77px;
			height: 29px;
		}

		// Nav
		ul {
			margin-top: 20px;
		}
	}
	
	.offers {
		
		.moving {
			background-image: url('../img/moving_2.jpg');
		}
		
	} 
	
	.blocks {

		.footer-block {
			min-height: 450px;
		}	
	}

	// Footer
	.footer {
		padding-top: 50px;
		padding-bottom: 50px;

		h1 {
			font-size: 35px;
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 30px;
		}
	}
}

// 992px
@media (max-width: 992px) {

	// Body
	body {
		padding-top: 95px;
	}

	// Top bar
	.top_bar {
		padding-left: 0;
		padding-right: 0;

		.first_ul {
			display: none;
		}
		.second_ul li {font-size: 16px;margin-left: 15px;margin-right: 0;}
	}

	// Navigation
	.navigation {
		padding-top: 10px;
		padding-bottom: 10px;

		ul {
			display: none;
			margin-top: 0;
			float: none;

			li {
				display: block;
				margin-right: 0;
				width: 100%;
				padding-top: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid rgba(0,0,0,.1);

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
		}

		.navicon {
			display: block;
		}

		// Sub nav
		li ul {
			position: relative;
			padding-top: 0;
			width: 100%;
		}
	}

	// Header
	.header {
		height: auto!important;
		padding-top: 50px;
		padding-bottom: 50px;

		h1 {
			font-size: 26px;
			margin-bottom: 20px;
			text-shadow: 0px 0px 20px rgba(0,0,0,0.3);
		}
		h2 {
			font-size: 20px;
			text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
		}
	}

	// Container position
	.container-pos1 {
		margin-top: 0;
	}
	.container-pos2 {
		margin-top: -150px;
		padding: 30px;
	}
	
	.content {
		width: 100% !important; 
	}
	
	// Offers
	.offers {

		.moving {
			background: #2f435e;
		}
	
		// Above
		.two_bar {
			padding: 15px;
			padding-left: 0;
			padding-right: 0;

			h2 {
				font-size: 20px;
			}
			p {
				padding-left: 20px;
			}
		}

		// Offer
		.offer {
			border-bottom: 1px solid rgba(0,0,0,.1);
			
			button {
				font-size: 22px;
				margin-top: 20px;
			}
		}
	}
	
	.products {
	
		.product_view {		
		
			&.actie-van-de-maand {
			
				a {
				
					img {
					
						float: none;	
					
					}
				
				}
			
			}
		
			text-align: center;	
						
			.product_image {
			
				a {
					float: left;
					width: 100%;	
				}
			}

			a.button {
				
				float: none;
				display: inline-block;
				
			}			
		}	
	}	

	// Blocks
	.blocks {

		.footer-block {
			min-height: 423px;
		}
	
		.block2 {
			font-size: 18px;
			padding: 0px;
			padding-top: 15px;
			padding-bottom: 15px;

			h1 {
				font-size: 30px;
				margin-bottom: 20px;
			}
			img {
				width: 100%;
			}
		}
		.block3 {
			font-size: 18px;
			padding-top: 30px;
			padding: 15px;

			h1 {
				font-size: 30px;
				margin-bottom: 20px;
			}
		}
	}

	// Footer
	.footer {
		padding-top: 30px;
		padding-bottom: 30px;

		h1 {
			font-size: 25px;
			margin-bottom: 20px;
		}
		p {
			margin-bottom: 20px;
		}

		input[type="text"],input[type="email"],input[type="tel"],textarea,input[type="submit"] {
			width: 100%;
		}
		
		
	}
}

// 768px
@media (max-width: 768px) {	

	.hide-xs {
		display: none;
	}
	
	.page_detail {	
	
		h1 {
			font-size: 24px;
		}
	
		.container {
		
			.page-content {
			
				.benefits {
						
					ul#benefits-list {
								
						li {
							float: left;
							background: url('../img/check.svg') 0px 8px no-repeat;
							background-size: 24px 24px;
							min-height: 60px;
							width: 100%;
							padding: 0px 0px 0px 40px;
							margin: 0px 0px 15px 0px;
						}
					}					
				}
			}
		}
	}

	// Wide img
	.wide-img {
		width: 100%;
		height: 420px;
		background: url('../img/intro-img-mobile.jpg') top center;
		overflow: auto;
	}

	.visisble-xs {
		display: block;
	}

	// Offers
	.offers {
	
		.offer {
		
			a {
		
				img {
					width: 80%;
					height: auto;
				}	
		
				h3 {
					font-size: 16px;
				}
				
				span {
					font-size: 16px;
					
					&.button {
						font-size: 16px;
						text-align: center;
						padding: 0px;
					}
				}
			}
		}

		// Flex boxes
		.flex-boxes {
			display: block;
		}

		// Month sale
		.month_sale {
			background-color: $main-color-orange;
			color: #FFF;

			h1 {
				font-size: 27px;
				color: #FFF;
			}
			img {
				border: 4px solid rgba(0,0,0,.1);
				max-width: 200px;
				width: 80%;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			p {font-weight: 300;}
			p:last-child {margin-top: 10px;}
			button {
				padding: 10px 15px;
				font-size: 18px;
				border-radius: 3px;
				border: none;
				color: $main-color-orange;
				margin-top: 10px;
				background: #FFF;
			}
		}

		// Moving
		.moving {
			color: #FFF;
			padding: 30px 15px;
			flex: 1;

			h1 {
				font-size: 30px;
				color: #FFF;
			}
			p {
				margin-top: 30px;
				margin-bottom: 30px;
			}
			a {
				color: #FFF;
				text-decoration: underline;
			}
		}
	}

	// Container position
	.container-pos2 {
		margin-top: -150px;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	// Intro
	.intro {

		h1 {
			margin-bottom: 20px;
			font-size: 35px;
		}

		.reviews {
			text-align: center;
			margin-top: 50px;
			
			.home-review {
				padding: 0px 0px 40px 0px;
			}
			
			h2 {
				font-size: 36px;
			}
			h3 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			img {
				margin-top: 20px;
				margin-bottom: 20px;
			}
			p {
				line-height: 27px;
			}
		}
	}

	// Blocks
	.blocks {

		.footer-block {
			padding-top: 30px;
			padding-bottom: 30px;
			min-height: 100px;
			
			img {
				display: none;
			}
		}	
	
		.row {
			display: block;
		}
		.block1 {
			height: 250px;
		}
		.block2 img {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
			text-align: center;
		}
		.block2 h1, .block3 h1 {
			font-size: 25px;
		}
	}

	// Footer
	.footer {

		.col-md-4 {
			margin-bottom: 30px;
		}
	}

	// Products
	.products {
		margin-bottom: 0;
		.row {
			display: block;
		}

		.month_sale {
			background-color: $main-color-orange;
			color: #FFF;

			h1 {
				font-size: 27px;
				color: #FFF;
			}
				
			p {
				color: #FFF;
			}
				
			img {
				border: 4px solid rgba(0,0,0,.1);
				max-width: 200px;
				width: 80%;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			p {font-weight: 300;}
			p:last-child {margin-top: 10px;}
			button {
				padding: 10px 15px;
				font-size: 18px;
				border-radius: 3px;
				border: none;
				color: $main-color-orange;
				margin-top: 10px;
				background: #FFF;
			}	
		}
	}

	// Product detail
	.product_detail {
		.container {
			background: #FFF;
			padding-top: 20px;
			padding-bottom: 50px;
		}
	}
	
	#notice {
		display: none;
		position: fixed;
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 9999;
		
		#notice-box {
			position: absolute;
			width: 90%;
			height: 90%;
			background: #FFFFFF;
			left: 5%;
			margin: 0px;
			overflow-y: scroll;
		}
		
		.inner {
			float: left;
			width: 100%;
			padding: 40px 20px 20px 20px;
			color: #000;
		}
		
		.close-notice {
			
			position: absolute;
			right: 10px;
			top: 10px;
			width: 32px;
			height: 32px;
			background: url('../img/close.png');
			cursor: pointer;
			
		}
	}	
	
}

// 480px
@media (max-width: 480px) {

	.content {
		padding: 50px 20px 100px 20px !important;
	}

	#youtube {
		width: 300px;
		height: 145px;
	}

	// Container position
	.container-pos2 {
		margin-top: 0px;
	}

	// Intro
	.intro {

		h1 {
			font-size: 30px;
		}
	}

	.navigation {
		ul {
			max-height: 80vh;
			overflow-y: scroll;
			width: 100%; 
		}
	}
	
	.intro {

		h1 {
			margin-bottom: 20px;
			font-size: 35px;
		}

		.reviews {
			
			h2 {
				font-size: 24px;
			}
			
			h3 {
				font-size: 21px;
			}	
		}
	}	
}

